.proposal-detail-container {
    width: 100%;
    padding-top: 24px;
    padding-left: 108px;
    padding-right: 46px;
    padding-bottom: 47px;
    .btn-back {
        background: #ffffff;
        border: 1px solid #3898fc;
        box-shadow: inset 0px -1px 1px rgba(85, 102, 123, 0.4);
        border-radius: 8px;
        width: 120px;
        height: 48px;
        color: inherit;
        margin-bottom: 16px;
        &:hover {
            color: #3898fc;
        }
    }
    .proposal-detail-content {
        .left-part {
            .vote-details {
                background: #ffffff;
                box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.5);
                border-radius: 8px;
                padding: 40px 47px;
                .title {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #22304a;
                    margin-bottom: 16px;
                }
            }
        }
    }
}
