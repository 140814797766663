@import url('../../../../assets/styles/util.less');

.pc( {
        .building-header {
            margin-top: 66px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            .back {
                position: absolute;
                left: 50px;
                width: 120px;
            }

            h1 {
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 48px;
                color: #22304a;
            }

            .step-list {
                margin-top: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                .step-item {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #798488;
                    position: relative;

                    &.active {
                        color: #22304a;
                        &:before {
                            content: '';
                            position: absolute;
                            height: 2px;
                            bottom: -7px;
                            left: 0;
                            right: 0;
                            background: rgba(56, 152, 252, 1);
                        }
                    }

                    &:nth-child(n + 2) {
                        margin-left: 96px;
                    }
                }
            }
        }
    }

);
