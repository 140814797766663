.details {
    background: #ffffff;
    box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.5);
    border-radius: 8px;
    width: 100%;

    margin-bottom: 24px;
    .details-header {
        padding: 24px 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dedede;
        .details-header-name {
            .start-time {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;

                color: #798488;
                margin-bottom: 8px;
            }
            .title {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #22304a;
                margin-bottom: 8px;
            }
            .end-time {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                color: #23b2ef;
            }
        }
    }
    .text {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */

        color: #22304a;
        padding: 24px 50px;
        min-height: 200px;
        overflow: auto;
    }
}
