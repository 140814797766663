@import url('../../../assets/styles/util.less');

@headerHeight: 78px;

.pc( {
        header {
            display: flex !important;
            justify-content: space-between;
            background: #FFFFFF;
            box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
            -3px -3px 8px rgba(255, 255, 255, 0.8);
            height: @headerHeight;
            padding: 0 72px;
            top: 0px;
            left: 0;
            right: 0;
            position: fixed;
            z-index: 100;

            .logo {
                display: flex;
                align-items: center;

                img {
                    height: 40px;
                    object-fit: contain;
                }

                h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 28px;
                    letter-spacing: 0.02em;
                    color: #000000;
                    margin-left: 10px;
                }
            }

            .actions {
                display: flex;
                align-items: center;

                .btn-common.btn-create {
                    height: 48px;
                    width: 190px;
                    border-radius: 10px;
                    margin-left: 8px;
                }
            }
        }
    }

);
