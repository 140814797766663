@import url('../../../assets/styles/util.less');
@import url('../Header/pc.less');

@siderWith: 120px;

.pc( {
        nav {
            background: #FFFFFF;
            box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
            -3px -3px 8px rgba(255, 255, 255, 0.8);
            border-radius: 0px;
            width: @siderWith;
            height: 100vh;
            left: 0;
            top: 40px;
            position: fixed;
            z-index: 10;
            padding-top: @headerHeight;
            flex-direction: column;
            align-items: center;
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: none
            }

            .ant-btn.btn-dao {
                height: 79.99999237060577px;
                width: 79.99999237060577px;
                margin: 20px auto;
                border-radius: 8px;
                background: #FFFFFF;
                border: 0.5px solid #D8D8D8;
                box-sizing: border-box;
                box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
                -3px -3px 8px rgba(255, 255, 255, 0.8);
                padding: 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                &.active {
                    background: #FAFAFA;
                    border: 0.5px solid #D8D8D8;
                    box-sizing: border-box;
                    box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.5);
                }
            }

            .ant-btn.btn-add {
                margin: 20px auto;
                height: 79.99999237060577px;
                width: 79.99999237060577px;
                border-radius: 8px;
                background: #FFFFFF;
                border: 0.5px solid #D8D8D8;
                box-sizing: border-box;
                box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
                -3px -3px 8px rgba(255, 255, 255, 0.8);
                padding: 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

);
