@import url('../../assets/styles/util.less');
@import url('./Header/pc.less');
@import url('./Sider/pc.less');


.container {
    padding-top: @headerHeight;
    // padding-top: 136px;
    padding-left: @siderWith;
    background: white;
    min-height: 100vh;

    main {
        flex: 1;
    }
}
