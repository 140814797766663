.other-user-container {
    .out-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        color: #798488;
        margin-bottom: 12px;
    }
    .other-user-detail {
        background: #ffffff;
        box-shadow: inset 2px 2px 5px rgba(105, 141, 173, 0.5);
        border-radius: 8px;
        padding: 24px 38px;
        .title {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */

            color: #22304a;
            margin-bottom: 14px;
        }
        .list-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            .label {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height */

                color: #767676;
            }
            .value {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                text-align: right;

                color: #22304a;
            }
        }
    }
}
