@import url('../../../../assets/styles/util.less');

.pc( {
        .assets {
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;

                h3 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;
                    color: #22304a;
                }

                .actions {
                    display: flex;
                    align-items: center;

                    .ant-btn {
                        height: 48px;
                        width: 150px;
                        border-radius: 10px;

                        &:nth-child(n + 2) {
                            margin-left: 12px;
                        }
                    }
                }
            }

            .transactions {
                padding-top: 23px;

                .history-list {

                    .history-item {
                        height: 100px;
                        border-radius: 8px;
                        background: #FFFFFF;
                        border: 0.5px solid #D8D8D8;
                        box-sizing: border-box;
                        box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
                        -3px -3px 8px rgba(255, 255, 255, 0.8);
                        border: none;
                        padding: 0 42px;
                        align-items: center;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: space-between;

                        &:nth-child(n + 2) {
                            margin-top: 16px;
                        }

                        .left {
                            display: flex;
                            flex-direction: column;

                            span {
                                &:first-child {
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 20px;
                                    color: #22304a;
                                }

                                &:last-child {
                                    display: inline-block;
                                    margin-top: 10px;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #798488;
                                }
                            }
                        }

                        .right {
                            display: flex;
                            align-items: center;

                            .amount {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;

                                span {
                                    &:first-child {
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 20px;
                                        color: #22304a;
                                    }

                                    &:last-child {
                                        margin-top: 6px;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: 16px;
                                        line-height: 20px;
                                        color: #22304a;
                                    }
                                }
                            }

                            img {
                                width: 16px;
                                object-fit: contain;
                                margin-left: 13px;
                            }
                        }
                    }
                }

                .dao-tokens {
                    min-height: 349px;
                    border-radius: 8px;
                    padding: 32px 25px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 0.5px solid #D8D8D8;
                    box-sizing: border-box;
                    box-shadow: 5px 7px 13px rgba(174, 174, 174, 0.3),
                    -3px -3px 8px rgba(255, 255, 255, 0.8);

                    h3 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: -0.288889px;
                        color: #22304a;
                    }

                    .token {
                        width: 100%;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #D2D2D2;

                        &:nth-child(2) {
                            margin-top: 32px;
                        }

                        .logo {
                            height: 24px;
                            object-fit: contain;
                        }

                        .name {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #767676;
                            margin-left: 7px;
                        }

                        .number {
                            flex: 1;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #22304a;
                            text-align: right;
                            max-width: 100px;
                        }
                    }
                }
            }
        }
    }

);
